import React from 'react';
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	Box,
	Rating,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';

const ProductCard = () => {
	const navigate = useNavigate();
	return (
		<Box
			onClick={() => navigate('/productdetails')}
			style={{ border: '1px solid #d3d3d333' }}
			mt={1}
			display={'flex'}
			justifyContent={'space-between'}
			alignItems={'center'}>
			<Box
				style={{
					background: '#f5f5f5',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '180px',
					padding: 10,
				}}>
				<img
					src='https://m.media-amazon.com/images/I/61UGJ7z-sUL._AC_UY327_FMwebp_QL65_.jpg'
					style={{
						width: '100%',
						height: '100px',
						objectFit: 'contain',
						mixBlendMode: 'multiply', // Removes white background
					}}
				/>
			</Box>

			<Box ml={2} style={{ paddingRight: 10 }}>
				<span style={{ fontSize: 12 }}>
					Snpurdiri 60% Wired Gaming Keyboard
				</span>
				<br />
				<span style={{ fontSize: 10, color: 'grey' }}>
					200+ bought in past month
				</span>
				<br />
				<span>$500</span>
				<br />
				<span style={{ fontSize: 12 }}>Delivery</span>
				<br />
				<span style={{ fontSize: 12, color: 'grey' }}>Ships to India</span>
				<Box
					mt={1}
					style={{
						background: '#ffd814',
						textAlign: 'center',
						padding: 7,
						borderRadius: '40px',
					}}>
					<span style={{ fontSize: 13 }}>Add to Cart</span>
				</Box>
			</Box>
		</Box>
	);
};

export default ProductCard;
