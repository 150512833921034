import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../reducers/UiReducer';
import user from '../../assets/user.png';
import homeicon from '../../assets/homeIcon.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function SideDrawer() {
	const { drawermodal } = useSelector((state) => state.ui);
	const dispatch = useDispatch();
	const DrawerList = (
		<Box sx={{ width: 300 }} role='presentation' onClick={toggleDrawer(false)}>
			<Box style={{ background: '#232F3F' }}>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					style={{ padding: 12 }}>
					<Box></Box>
					<Box display={'flex'} alignItems={'center'}>
						<span style={{ color: 'white', fontSize: 12, fontWeight: 500 }}>
							Sign in
						</span>
						<img src={user} style={{ height: 20, marginLeft: 5 }} />
					</Box>
				</Box>
				<Box style={{ padding: '5px 18px' }}>
					<span style={{ color: 'white', fontWeight: 500, fontSize: 18 }}>
						Browse
					</span>
					<br />
					<span style={{ color: 'white', fontSize: 24 }}>Amazon</span>
				</Box>
			</Box>
			<Divider />
			<Box style={{ padding: 16 }}>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}>
					<span style={{ color: '#232F3F', fontWeight: 'bold', fontSize: 17 }}>
						Amazon Home
					</span>
					<img src={homeicon} style={{ height: 25 }} />
				</Box>
			</Box>
			<Divider style={{ border: '3px solid #D3D9D7' }} />
			<Box style={{ padding: 16 }}>
				<Box>
					<span style={{ color: '#232F3F', fontWeight: 'bold', fontSize: 17 }}>
						Trending
					</span>
					<p style={{ fontSize: 14, color: '#232F3F', fontWeight: 500 }}>
						Movers and Shackers
					</p>
				</Box>
			</Box>{' '}
			<Divider style={{ border: '3px solid #D3D9D7' }} />
			<Box style={{ padding: 16 }}>
				<Box mb={3}>
					<span style={{ color: '#232F3F', fontWeight: 'bold', fontSize: 17 }}>
						Top Departments
					</span>
				</Box>
				<p style={{ fontSize: 14, color: '#232F3F', fontWeight: 500 }}>Home</p>
				<p style={{ fontSize: 14, color: '#232F3F', fontWeight: 500 }}>
					Health & Household
				</p>
				<p style={{ fontSize: 14, color: '#232F3F', fontWeight: 500 }}>Books</p>
				<p style={{ fontSize: 14, color: '#232F3F', fontWeight: 500 }}>PC</p>
				<Box display={'flex'} alignItems={'center'}>
					<p style={{ fontSize: 14, color: '#232F3F', fontWeight: 500 }}>
						See all
					</p>
					<KeyboardArrowDownIcon />
				</Box>
			</Box>
		</Box>
	);

	return (
		<div>
			<Drawer open={drawermodal} onClose={() => dispatch(toggleDrawer(false))}>
				{DrawerList}
			</Drawer>
		</div>
	);
}
