import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import banner from '../../assets/banner.png';

// Dynamically import the Slider component
const Slider = lazy(() => import('react-slick'));

const HeroSlider = () => {
	// Slider settings
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 5000,
	};

	return (
		<section
		// className='home-slider position-relative pt-50'
		>
			{/* Wrap Slider in Suspense with a fallback loading state */}
			<Suspense fallback={<div>Loading...</div>}>
				<Slider
					{...settings}
					className='hero-slider-1 dot-style-1 dot-style-1-position-1'>
					<img className='animated slider-1-1' src={banner} alt='Slider 1' />
					<img className='animated slider-1-1' src={banner} alt='Slider 1' />
					<img className='animated slider-1-1' src={banner} alt='Slider 1' />
					<img className='animated slider-1-1' src={banner} alt='Slider 1' />
				</Slider>
			</Suspense>
		</section>
	);
};

export default HeroSlider;
