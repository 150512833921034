import React from 'react';
import { Box, Grid } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const Footer = () => {
	return (
		<>
			<Box style={{ background: '#364759', padding: 1 }}>
				<Box style={{ textAlign: 'center' }}>
					<ArrowDropUpIcon style={{ color: 'white' }} />
					<p
						style={{
							fontSize: 12,
							color: 'white',
							textAlign: 'center',
							marginBlockStart: '0em',
						}}>
						TOP OF PAGE
					</p>
				</Box>
			</Box>
			<div
				style={{
					background: '#232f3e',
					paddingLeft: 10,
					paddingRight: 10,
					marginTop: 16,
				}}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>Amazon.com</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>Your Lists</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>
								Registry & Gift List
							</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>Your Account</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>
								Sell Product on Amazon
							</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>
								Recalls and Product Saftey Alerts
							</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>Customer Service</p>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>Your Orders</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>Gift Cards</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>Find a Gift</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>Browsing History</p>
						</Box>
						<Box>
							<p style={{ color: '#fff', fontSize: 14 }}>Your Returns</p>
						</Box>
					</Grid>
				</Grid>
			</div>
			<Box style={{ background: '#131921', padding: 10 }}>
				<p style={{ color: 'white', textAlign: 'center', fontSize: 15 }}>
					Already a customer? Sign in
				</p>
				<p style={{ textAlign: 'center', color: 'lightgrey', fontSize: 10 }}>
					© 1996-2025, Amazon.com, Inc. or its affiliates
				</p>
			</Box>
		</>
	);
};

export default Footer;
