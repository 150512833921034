import React from 'react';
import Header from '../components/resuableComponents/Header';
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import banner from '../assets/banner.png';
import ShoppingCard from '../components/resuableComponents/ShoppingCard';
import Footer from '../components/resuableComponents/Footer';
import ProductGrid from '../components/resuableComponents/ProductGrid';
import ShoppingDeal from '../components/resuableComponents/ShoppingDeal';
import HeroSlider from '../components/resuableComponents/HeroSlider';

const LandingPage = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<>
			{matches ? (
				<>
					<Header />
					<div className='homeBanner'>
						<HeroSlider />
					</div>
					<div
						style={{
							display: 'flex',
							padding: 6,
							overflowX: 'auto',
							gap: 10,
							whiteSpace: 'nowrap',
							// width: '100%', // Ensure it takes up only available space
							scrollbarWidth: 'none', // Hide scrollbar in Firefox
							msOverflowStyle: 'none', // Hide scrollbar in IE/Edge
							top: '-30px',
							position: 'relative',
						}}>
						<div style={{ display: 'flex', gap: 10 }}>
							<ShoppingCard />
							<ShoppingCard />
							<ShoppingCard />
							<ShoppingCard />
						</div>
					</div>
					<Box
						style={{
							background: '#364759',
							padding: 8,
							position: 'relative',
							top: '-32px',
							paddingLeft: 55,
							paddingRight: 55,
						}}>
						<p
							style={{
								textAlign: 'center',
								fontSize: 13,
								color: '#fff',
								fontWeight: 500,
							}}>
							You are on amazon.com. You can also shop onAmazon India for
							millions of products with fast local delivery.
						</p>
					</Box>
					<br />
					<Divider style={{ border: '1px solid lightgrey' }} />
					<Box style={{ padding: 10 }}>
						<h1 style={{ fontSize: 16 }}>Continue Shopping Deal</h1>
						<ShoppingDeal />
					</Box>
					{Array.from(Array(5)).map((i) => {
						return (
							<Box style={{ padding: 10 }}>
								<h1 style={{ fontSize: 16 }}>
									Scope the top PCs & Accessories
								</h1>
								<ProductGrid />
							</Box>
						);
					})}
					<Footer />
				</>
			) : (
				''
			)}
		</>
	);
};

export default LandingPage;
