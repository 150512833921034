import React from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import { Box, Divider, Grid } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';

const CartPage = () => {
	return (
		<>
			<Header />
			<Box style={{ padding: 10 }}>
				<span style={{ fontSize: 20 }}>Subtotal</span>
				<span style={{ fontWeight: 'bold', fontSize: 20, marginLeft: 10 }}>
					₹500
				</span>
				<Box
					mt={1}
					style={{
						background: '#ffd814',
						textAlign: 'center',
						padding: 12,
						borderRadius: '40px',
					}}>
					<span style={{ fontSize: 14 }}>Procced to checkout(1 item)</span>
				</Box>
				<br />
				<Divider />
			</Box>
			<Box style={{ padding: '0px 10px' }}>
				<span style={{ color: '#4e4ea4', fontSize: 14, fontWeight: 400 }}>
					Select all items
				</span>
			</Box>
			<Box style={{ background: '#f5f5f5', padding: 10 }}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<img
							src='https://m.media-amazon.com/images/I/61UGJ7z-sUL._AC_UY327_FMwebp_QL65_.jpg'
							style={{
								width: '100%',
								height: '100px',
								objectFit: 'contain',
								mixBlendMode: 'multiply',
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						<Box>
							<span style={{ fontSize: 13 }}>
								Snpurdiri 60% Wired Gaming Keyboard Snpurdiri 60% Wired Gaming
							</span>
							<br />
							<span style={{ fontSize: 12 }}>200+ bought in past month</span>
							<br />
							<span style={{ fontWeight: 500 }}>₹500</span>
						</Box>
						<Box display={'flex'} mt={2}></Box>
					</Grid>
				</Grid>
				<Box display={'flex'} justifyContent={'space-between'}>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						style={{
							border: '3px solid rgb(255, 216, 20)',
							borderRadius: '25px',
							width: '30%',
							padding: '3px',
						}}>
						<DeleteOutlineIcon style={{ fontSize: 20 }} />
						<span style={{ fontSize: 15, fontWeight: 'bold' }}>5</span>
						<AddIcon style={{ fontSize: 20 }} />
					</Box>
					<Box
						style={{
							border: '1px solid grey',
							padding: '5px 15px',
							borderRadius: 30,
							fontSize: 12,
						}}>
						Delete
					</Box>
					<Box
						ml={1}
						style={{
							border: '1px solid grey',
							padding: '5px 15px',
							borderRadius: 30,
							fontSize: 12,
						}}>
						Save for later
					</Box>
				</Box>
			</Box>
			<Footer />
		</>
	);
};

export default CartPage;
