import React from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import HeroSlider from '../components/resuableComponents/HeroSlider';
import ProductSlider from '../components/resuableComponents/ProductSlider';
import { Box } from '@mui/material';

const ProductDetail = () => {
	return (
		<>
			<Header />
			<Box style={{ padding: 10 }}>
				<span style={{ fontSize: 13 }}>
					Snpurdiri 60% Wired Gaming Keyboard Snpurdiri 60% Wired Gaming
				</span>
				<br />
				<span style={{ fontSize: 12 }}>200+ bought in past month</span>
				<br />
			</Box>
			<Box style={{ padding: 10 }}>
				<ProductSlider />
				<span style={{ fontWeight: 500 }}>Price: ₹500</span>
				<Box
					mb={2}
					mt={1}
					style={{
						background: '#ffd814',
						textAlign: 'center',
						padding: 7,
						borderRadius: '40px',
					}}>
					<span style={{ fontSize: 13 }}>Add to Cart</span>
				</Box>
			</Box>
			<Footer />
		</>
	);
};

export default ProductDetail;
