import React from 'react';
import {
	Grid,
	Card,
	CardMedia,
	CardContent,
	Typography,
	Box,
} from '@mui/material';
import lap from '../../assets/watch.png';
import { useNavigate } from 'react-router-dom';

const categories = [
	{
		title: '50',
		image: lap,
	},
	{
		title: '40',
		image: lap,
	},
	{
		title: '60',
		image: lap,
	},
	{
		title: '90',
		image: lap,
	},
];

const ShoppingDeal = () => {
	const navigate = useNavigate();
	return (
		<Grid container justifyContent='center' spacing={2}>
			{categories.map((category, index) => (
				<Grid
					item
					key={index}
					xs={6}
					sm={6}
					md={3}
					onClick={() => navigate('/product')}>
					<img src={category?.image} style={{ width: '100%' }} />
					<Box mt={1}>
						<span
							style={{
								background: '#C90E39',
								color: 'white',
								padding: 5,
								borderRadius: 2,
								fontSize: 12,
								fontWeight: 500,
							}}>
							{`${category.title}% off`}
						</span>
						<span
							style={{
								fontSize: 12,
								color: '#C90E39',
								fontWeight: 'bold',
								marginLeft: 5,
							}}>
							Limited Time Deal
						</span>
					</Box>
				</Grid>
			))}
		</Grid>
	);
};

export default ShoppingDeal;
