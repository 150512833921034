import { createSlice } from '@reduxjs/toolkit';
export const storeData = async (key, value) => {
	try {
		const now = new Date();
		const dataWithExpiry = {
			value: value,
			expiry: now.getTime() + 10 * 60 * 1000, // Convert minutes to milliseconds
		};
		const jsonValue = JSON.stringify(dataWithExpiry);
		localStorage.setItem(key, jsonValue);
	} catch (e) {
		console.error('Error saving data:', e); // Handle saving error
	}
};

export const getData = async (key) => {
	try {
		const jsonValue = localStorage.getItem(key);
		// console.log('jsonValue', jsonValue);
		if (!jsonValue) {
			return null; // Key not found
		}
		const dataWithExpiry = JSON.parse(jsonValue);
		console.log('dataWithExpiry', dataWithExpiry);
		const now = new Date();
		if (now.getTime() > dataWithExpiry.expiry) {
			// Data has expired
			localStorage.removeItem(key);
			// console.log('55', dataWithExpiry);
			return null;
		}
		return dataWithExpiry.value; // Return the value if not expired
	} catch (e) {
		console.error('Error reading data:', e); // Handle read error
		return null;
	}
};

const UiReducer = createSlice({
	name: 'ui',
	initialState: {
		tab: 'men',
		cart: (await getData('cart')) ? await getData('cart') : null,
		modal: false,
		whishlist: (await getData('whishlist')) ? await getData('whishlist') : null,
		productDetail: (await getData('productDetail'))
			? await getData('productDetail')
			: null,
		drawermodal: false,
		drawerNotificationmodal: false,
	},
	reducers: {
		setTab(state, action) {
			state.tab = action.payload;
		},
		toggleDrawer(state, action) {
			state.drawermodal = action.payload;
		},
	},
});

const { actions } = UiReducer;

export const { setTab, toggleDrawer } = actions;

export default UiReducer;
