import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import ProductCard from '../components/resuableComponents/ProductCard';

const ProductPage = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{matches ? (
				<>
					<Header />
					<Divider />
					<Box style={{ padding: 10 }}>
						{Array.from(Array(10)).map((i) => {
							return <ProductCard />;
						})}
					</Box>
					<Footer />
				</>
			) : (
				''
			)}
		</>
	);
};

export default ProductPage;
