import { axiosInstance } from '../Axios';
import { createSlice } from '@reduxjs/toolkit';
import { getData, storeData } from './UiReducer';

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		loginInfo: (await getData('loginInfo')) ? await getData('loginInfo') : null,
	},
	reducers: {
		loginRequest(state, action) {
			state.loading = true;
		},
		loginSuccess(state, action) {
			state.loading = false;
			state.loginInfo = action.payload;
			storeData('loginInfo', action.payload);
			state.error = null;
		},
		loginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		'Content-Type': 'application/json',
	},
};
export const { loginRequest, loginSuccess, loginFail } = actions;

export const loginDispatch = (bodyData, navigate, Swal) => async (dispatch) => {
	try {
		dispatch(loginRequest());
		const { data } = await axiosInstance.post(
			'/customer/login',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(loginSuccess(data));
	} catch (error) {
		dispatch(
			loginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default HomeReducer;
