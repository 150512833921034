import { Card, CardContent, Typography, CardMedia } from '@mui/material';

const ShoppingCard = () => {
	return (
		<Card
			sx={{
				width: 120,
				borderRadius: 1,
				boxShadow: 3,
				overflow: 'hidden',
			}}>
			<CardContent sx={{ padding: 1 }}>
				<Typography fontSize={10}>Keep shopping for</Typography>
				<Typography fontSize={10}>Women's earrings</Typography>
			</CardContent>
			<CardMedia
				component='img'
				height='100'
				image='https://images-na.ssl-images-amazon.com/images/G/01/AmazonExports/Events/2024/Stores-Gaming/FinalGraphics/Fuji_Gaming_store_Dashboard_card_1x_EN._SY304_CB564799420_.jpg' // Replace with your image URL
				alt="Women's earrings"
			/>
		</Card>
	);
};

export default ShoppingCard;
