import { Box, IconButton } from '@mui/material';
import React from 'react';
import logo from '../../assets/amazonLogo.png';
import cart from '../../assets/cart.png';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import location from '../../assets/location.png';
import user from '../../assets/user.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import SideDrawer from './SideDrawer';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from '../../reducers/UiReducer';
import { useNavigate } from 'react-router-dom';

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	return (
		<>
			<Box style={{ background: '#232F3F', padding: 6 }}>
				<Box display={'flex'} justifyContent={'space-between'}>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<DensityMediumIcon
							style={{ fontSize: 22, color: '#fff' }}
							onClick={() => dispatch(toggleDrawer(true))}
						/>
						<img
							onClick={() => navigate('/')}
							src={logo}
							style={{ height: 24, marginLeft: 5, marginTop: 8 }}
						/>
					</Box>
					<Box
						style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
						<span style={{ color: 'white' }}>Sign in</span>
						<KeyboardArrowRightIcon
							style={{ color: 'white', fontSize: 16, marginTop: 5 }}
						/>
						<img src={user} style={{ height: 25, marginRight: 40 }} />
						<span
							style={{
								position: 'relative',
								fontSize: '17px',
								marginBottom: '17px',
								color: '#ff9900',
								marginRight: -26,
							}}>
							7
						</span>
						<img
							onClick={() => navigate('/cart')}
							src={cart}
							style={{ height: 26 }}
						/>
					</Box>
				</Box>
				<Box
					style={{ background: 'white' }}
					mr={'2px'}
					mt={'6px'}
					display={'flex'}
					alignItems={'center'}
					border={'1px solid #ccc'}
					borderRadius={2}
					overflow={'hidden'}>
					<input
						type='text'
						placeholder='Search Amazon'
						style={{
							flex: 1,
							padding: '14px',
							border: 'none',
							outline: 'none',
							fontSize: '16px',
						}}
					/>
					<IconButton
						sx={{
							backgroundColor: '#febd69',
							borderRadius: 0,
							padding: '12px',
							borderRadius: '6px',
							'&:hover': { backgroundColor: '#e6a34b' },
						}}>
						<SearchIcon sx={{ color: '#000' }} />
					</IconButton>
				</Box>
				<Box
					display='flex'
					sx={{
						padding: '2px',
						overflowX: 'auto', // Use 'auto' or 'scroll' instead of 'smooth'
						width: '100%',
						alignItems: 'center',
						whiteSpace: 'nowrap', // Ensures text stays in one line
					}}>
					<p
						style={{
							color: 'white',
							lineHeight: '8px',
							fontSize: 14,
							fontWeight: 500,
						}}>
						Deals
					</p>
					<p
						style={{
							color: 'white',
							marginLeft: 10,
							lineHeight: '8px',
							fontSize: 14,
							fontWeight: 500,
						}}>
						Amazon Basic
					</p>
					<p
						style={{
							color: 'white',
							marginLeft: 10,
							lineHeight: '8px',
							fontSize: 14,
							fontWeight: 500,
						}}>
						Music
					</p>
					<p
						style={{
							color: 'white',
							marginLeft: 10,
							lineHeight: '8px',
							fontSize: 14,
							fontWeight: 500,
						}}>
						Livestreams
					</p>
					<p
						style={{
							color: 'white',
							marginLeft: 10,
							lineHeight: '8px',
							fontSize: 14,
							fontWeight: 500,
						}}>
						Amazon Basic
					</p>
					<p
						style={{
							color: 'white',
							marginLeft: 10,
							lineHeight: '8px',
							fontSize: 14,
							fontWeight: 500,
						}}>
						Amazon Basic
					</p>
				</Box>
			</Box>
			<Box
				style={{ background: '#364759', padding: 8 }}
				display={'flex'}
				alignItems={'center'}>
				<img src={location} style={{ height: 20 }} />
				<span
					style={{
						marginLeft: 5,
						fontSize: 14,
						color: 'white',
						fontWeight: 500,
					}}>
					Deliver to India
				</span>
				<KeyboardArrowDownIcon style={{ color: 'white', marginTop: 5 }} />
			</Box>
			<SideDrawer />
		</>
	);
};

export default Header;
