import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import lap from '../../assets/laptop.png';

const categories = [
	{
		title: 'Desktops',
		image: lap,
	},
	{
		title: 'Laptop',
		image: lap,
	},
	{
		title: 'Hard Drives',
		image: lap,
	},
	{
		title: 'PC Accessories',
		image: lap,
	},
];

const ProductGrid = () => {
	return (
		<Grid container justifyContent='center' spacing={2}>
			{categories.map((category, index) => (
				<Grid item key={index} xs={6} sm={6} md={3}>
					<img src={category?.image} style={{ width: '100%' }} />
					<span style={{ fontSize: 14 }}>{category.title}</span>
				</Grid>
			))}
		</Grid>
	);
};

export default ProductGrid;
