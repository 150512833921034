import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import { useMediaQuery, useTheme } from '@mui/material';
import ProductPage from '../pages/ProductPage';
import CartPage from '../pages/CartPage';
import ProductDetail from '../pages/ProductDetail';
const MainRouter = () => {
	return (
		<div>
			<Routes>
				<Route path='/' exact element={<LandingPage />} />
				<Route path='/cart' exact element={<CartPage />} />
				<Route path='/productdetails' exact element={<ProductDetail />} />
				<Route path='/product' exact element={<ProductPage />} />
			</Routes>
		</div>
	);
};

export default MainRouter;
